import React, { useEffect, useMemo, useState } from 'react';
import logo from './logo.svg';
import './App.css';

import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { sensitiveHeaders } from 'http2';
import styled from 'styled-components';

interface MapInventoryResponse {
  // type: string
  features: Feature[]
}
interface Feature {
  geometry: Geometry
  properties: {
    station: Station
    bike_angels?: { score: number }
    bikes: Bike[]
  }

}
interface Bike {
  charge: number
  id: string
  pedal_assist: boolean
  renting: boolean
}
interface Geometry {
  type: 'Point'
  coordinates: [number, number]

}
interface Station {
  id: string
  bikes_available: number
  bikes_disabled: number
  capacity: number
  docks_available: number
  docks_disabled: number
  last_reported: EpochTimeStamp
  name: string
  renting: boolean
  returning: boolean
}
interface Coordinates {
  latitude: number
  longitude: number
}
interface Position extends Coordinates {
  accuracy: number
  heading?: number | null
  altitude?: number | null
  altitudeAccuracy?: number | null
  speed?: number | null

}
type AugmentedFeature = Exclude<Exclude<ReturnType<typeof useNearbyStations>, undefined>['data'], undefined>[number]
const SCORE_MULTIPLE = 3
const MAX_SCORE = 4

const useMapInventory = () => {
  return useQuery(['mapInventory'], async () => {

    const url = "https://layer.bicyclesharing.net/map/v1/nyc/map-inventory"
    // const url = 'https://cb.mydns.name/map/v1/nyc/map-inventory'
    const res = fetch(url, {
      "headers": {},
      "body": null,
      "method": "GET",
      "mode": "cors",
      "credentials": "omit"
    });

    const parsedResponse = (await (await res).json()) as MapInventoryResponse
    return parsedResponse.features.filter(
      ({ properties: { station } }) => station.renting || station.returning
    ).filter(s => {
      const score = s.properties.bike_angels?.score
      if (!score) {
        return true
      }
      if (score < 0) {
        return s.properties.station.bikes_available > 0
      }
      if (score > 0) {
        return s.properties.station.docks_available > 0
      }
    })
  },
    { keepPreviousData: true } // prevent page from blanking out during refresh
  )
}

const getStationColor = (s: Feature) => {
  const station = s.properties.station
  const score = s.properties.bike_angels?.score ?? 0
  const RED = '#D00000'
  const GREEN = '#006000'
  let color: string | undefined
  if (score !== 0) {
    color = score > 0 ? RED : GREEN
  } else {
    if (station.bikes_available === 0) {
      color = RED

    } else if (station.docks_available === 0) {
      color = GREEN
    }
  }
  return color
}
const useNearbyStations = () => {
  const mapInventory = useMapInventory()
  const geoLoc = useGeoLoc()
  return useMemo(() => {
    if (!geoLoc) {
      return
    }
    const sorted = {
      ...mapInventory,
      data: mapInventory.data?.map((s) => {
        return {
          ...s,
          ...calculateDistance(
            geoLoc,
            {
              longitude: s.geometry.coordinates[0],
              latitude: s.geometry.coordinates[1],
            },
            s.properties.station.name
          ),
          color: getStationColor(s),
        }
      }).sort((a, b) => {
        return a.distance === b.distance ? 0 : (a.distance > b.distance ? 1 : -1)
      }).slice(0, 500)
    }
    return sorted
  }, [geoLoc, mapInventory])

}


const useGeoLoc = () => {
  const [curPosition, setCurPosition] = useState<Position>()
  useEffect(
    () => {
      const watcherId = navigator.geolocation.watchPosition((p) => {
        setCurPosition({
          latitude: p.coords.latitude,
          longitude: p.coords.longitude,
          accuracy: p.coords.accuracy,
          heading: p.coords.heading,
          altitude: p.coords.altitude,
          altitudeAccuracy: p.coords.altitudeAccuracy,
          speed: p.coords.speed,
        })
      }, null, {
        maximumAge: 10000,
      })
      return () => { navigator.geolocation.clearWatch(watcherId) }
    }, []
  )
  return curPosition
}
function calculateDistance(a: Coordinates, b: Coordinates, name: string) {
  const earthRadius = 6371; // km
  const dlat = b.latitude - a.latitude; // Difference of latitude
  const dlon = b.longitude - a.longitude; // Difference of longitude

  const distLat = (dlat * Math.PI * earthRadius) / 180; // Vertical distance
  const distLon = (dlon * Math.PI * earthRadius) / 180; // Horizontal distance
  

  const distance = Math.sqrt(Math.pow(distLat, 2) + Math.pow(distLon, 2))
  return { distance, distLat, distLon }
}


const formatDistance = (km: number) => {
  if (km > 1) {
    return `${Math.round(km * 10) / 10}km`
  } else {
    return `${Math.round(km * 1000)}m`
  }
}
const ButtonBar = styled.div`
  display: flex;
  gap: 4px;
  position: absolute;
  bottom: 4px;
  left: 8px;
`
const Button = styled.div`
    width: 16px;
    height: 16px;
    background-color: yellow;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`


function compassHeading(alpha:number, beta:number, gamma:number) {
  //https://stackoverflow.com/a/21829819

  // Convert degrees to radians
  const alphaRad = alpha * (Math.PI / 180);
  const betaRad = beta * (Math.PI / 180);
  const gammaRad = gamma * (Math.PI / 180);

  // Calculate equation components
  const cA = Math.cos(alphaRad);
  const sA = Math.sin(alphaRad);
  const cB = Math.cos(betaRad);
  const sB = Math.sin(betaRad);
  const cG = Math.cos(gammaRad);
  const sG = Math.sin(gammaRad);

  // Calculate A, B, C rotation components
  const rA = - cA * sG - sA * sB * cG;
  const rB = - sA * sG + cA * sB * cG;
  const rC = - cB * cG;

  // Calculate compass heading
  let compassHeading = Math.atan(rA / rB);

  // Convert from half unit circle to whole unit circle
  if(rB < 0) {
    compassHeading += Math.PI;
  }else if(rA < 0) {
    compassHeading += 2 * Math.PI;
  }

  // Convert radians to degrees
  compassHeading *= 180 / Math.PI;

  return compassHeading;

}


const useHeading = () => {
  const [heading, setHeading] = useState<number | undefined>()
  useEffect(() => {




    //@ts-ignore
    window.ondeviceorientationabsolute = (e: DeviceOrientationEvent) => {
        setHeading(compassHeading(e.alpha, e.beta, e.gamma))
    }

    // const options = { frequency: 60, referenceFrame: "device" };
    // //@ts-ignore
    // const sensor  = new window.AbsoluteOrientationSensor(options);


    // sensor.addEventListener("reading", () => {
    //   console.log('READINGEVENT', sensor.heading, sensor.quaternion ,sensor)
    //   setHeading(sensor.quaternion?.[0] ?? 0)
    // });
    // sensor.start();


    // window.addEventListener('deviceorientation')

  }, [])
  return heading 

}
const MapPage = () => {
  const myHeading = useHeading() 
  const [maxDistance, setMaxDistance] = useState(1) // (units are km)
  const [referenceRot, setReferenceRot] = useState<number|null>(0) // (units are km)
  const [activeStation, setActiveStation] = useState<AugmentedFeature | undefined>(undefined)
  const mapInventory = useNearbyStations()
  const nearestStations = useMemo(() => { return mapInventory?.data?.filter(s => s.distance < maxDistance) }, [mapInventory?.data, maxDistance])
  // const diagPx = Math.sqrt(Math.pow(window.innerHeight, 2) + Math.pow(window.innerWidth, 2) )
  return (
    <div
      style={{
        // position: "absolute",
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
      }}
    >
      {activeStation ?
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: '100vw',
            height: '64px',
            opacity: '.8',
            zIndex: 2,
            backgroundColor: 'white',
          }}
        >
          <table>
            <tbody>
              {activeStation? 
                <>
                  {StationRow(activeStation)}
                </>
              : (<tr></tr>)}
            </tbody>

          </table>
        </div>
        : undefined}

        <ButtonBar>
          <Button
            onClick={() => { setMaxDistance(v => v * .8) }}
          >
            +
          </Button>
          <Button
            onClick={() => { setMaxDistance(v => v * 1.2) }}
          >
            -
          </Button>
          <Button onClick={() => setReferenceRot(myHeading ?? 0)}>
            O
          </Button>
          <Button onClick={() => setReferenceRot(null) }>
            x
          </Button>
          <Button>
          {referenceRot}
          </Button>
          <Button style={{width: '200px'}}>
          {myHeading?.toString()}
          </Button>

        </ButtonBar>
      <div
        style={{
          backgroundColor: '#FF000025',
          position: "absolute",
          width: '100%',
          height: '100%',
        }}
      >
        <div 
          style={{
            position: 'absolute',
            top: '3%',
            left: '50%',
            width: '20px',
            height: '20px',
            backgroundColor: 'orange',
            color: 'white'
          }}
        > N </div>

        <div
          style={{
            color: 'white',
            position: 'absolute',
            top: 'calc(50% + 10px)',
            left: '50%',
            borderRadius: '10px 10px 0 0',
            width: '5px',
            height: '20px',
            // backgroundColor: 'blue',
            background: 'linear-gradient(0deg, #ccc, #ff1212)',
            // rotate: `${myloc?.heading}deg`,
            rotate: referenceRot !== null ? `${(myHeading ?? 0) + referenceRot }deg`: undefined,
            transition: 'rotate 2s',
          }}
        />
        {nearestStations?.map(s => {
          const perLat = -s.distLat / maxDistance
          const perLon = s.distLon / maxDistance
          const score = s.properties.bike_angels?.score ?? 0
          const station = s.properties.station
          return (
            <div
              style={{
                position: 'absolute',
                top: `${((perLat * 100) / 2) + 50}%`,
                left: `${((perLon * 100) / 2) + 50}%`,
                borderRadius: '5px',
                width: '24px',
                height: '24px',
                fontSize: '10px',
                textAlign: 'center',
                backgroundColor: s.color || '#FFFFFF',
                color: s.color ? 'white' : 'black',
                cursor: "pointer",
                opacity: `${Math.max(40,
                  ((Math.abs(s.properties.bike_angels?.score ?? 0)) / MAX_SCORE) * 120)
                  }%`,
                fontWeight: score ? (300 + Math.abs(score) * 100) : 300,
                border: station.id === activeStation?.properties.station.id ? 'red 1px solid': '',
              }}
              onClick={() => setActiveStation(s)}
            >
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <span>
                  {(s.properties.bike_angels?.score ?? 0) * SCORE_MULTIPLE}<span style={{fontSize: '5px', verticalAlign: 'top'}}> 💸 </span>
                </span>
                <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                  {!score ? 
                    <>
                      <span>
                        {station.bikes_available}
                      </span>
                      :
                      <span>
                        {station.docks_available}
                      </span>
                    </>
                  :
                    <>
                      {score > 0 ? s.properties.station.bikes_available : station.docks_available}
                    </>
                  }

                </div>

              </div>

            </div>
          )

        })}
      </div>
    </div>)
}
const MainPage = () => {
  const mapInventory = useNearbyStations()
  const myloc = useGeoLoc()
  return (
    <>
      <style>
        {
          `
      table {
        //background-color: red;
      }
      table td {
        border: black 1px solid;
      }
      table tr:nth-child(odd) { background-color: #ccc; }
      `}
      </style>
      <table>
        <thead>
          <td>dist</td>
          <td>pts</td>
          <td>Name {myloc?.heading?.toString().slice(0, 5)}</td>
          <td>bikes</td>
          <td>docks</td>
        </thead>
        <tbody>
          {mapInventory?.data?.map(StationRow)}
        </tbody>

      </table>

    </>)

}

const StationRow = (s: AugmentedFeature) => {
  const color = getStationColor(s)
  const score = s.properties.bike_angels?.score ?? 0
  return (
    <tr
      key={s.properties.station.id}
      style={{
        color,
        fontWeight: score ? (300 + Math.abs(score) * 100) : 300
      }}
    >
      <td>
        {formatDistance(s.distance)}
      </td>
      <td>
        {s.properties?.bike_angels?.score}
      </td>
      <td>
        {s.properties.station.name}
      </td>
      <td>
        {s.properties.station.bikes_available} 🚲
      </td>
      <td>
        {s.properties.station.docks_available} []
      </td>
    </tr>

  )
}
function App() {
  const queryClient = new QueryClient()
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <MapPage />
        <MainPage />

      </QueryClientProvider>
    </div>

  );
}

export default App;
